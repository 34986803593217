import styled from 'styled-components'

export const SinglePolicieContainer = styled.div`
    padding: 2.2rem 0;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    &:not(:last-child) {
        border-bottom: 1px solid #f6f7f8;
    }
    .inputs {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-around;
        & > :first-child {
            margin-bottom: 1rem;
        }
        .label-input {
            display: flex;
            align-items: center;
            p {
                font-size: 1.4rem;
                margin-right: 1rem;
                font-style: italic;
            }
        }
    }
    .add-word {
        background-color: #fff;
        border: none;
        height: 100%;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        color: #e74b70;
        font-size: 1.4rem;
        cursor: pointer;
        transition: background-color 0.3s;
        border: none;
        &:hover {
            background-color: #e5e5e5;
        }
    }
    .forbidden-words {
        width: 100%;
        background-color: #f6f7f8;
        padding: 2.5rem 1rem 1rem 1rem;
        border-radius: 0.5rem;
        display: flex;
        flex-wrap: wrap;
        gap: 0.3rem;
        max-height: 15rem;
        overflow-y: auto;
        .word {
            background-color: #fff;
            border-radius: 0.5rem;
            padding: 0.5rem 1rem;
            display: flex;
            align-items: center;
            max-width: 10rem;
            p {
                max-width: 6.5rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 1.4rem;
                margin-right: 0.3rem;
            }
            button {
                background-color: transparent;
                border: none;
                display: flex;
                align-items: center;
                color: #ff0000;
                font-size: 1.4rem;
                cursor: pointer;
                transition: color 0.3s;
                &:hover {
                    color: #f00;
                }
            }
        }
    }
`
