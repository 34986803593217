import React, { useState } from 'react'
import styled from 'styled-components'

const TooltipWrapper = styled.div`
    position: relative;
    display: inline-block;
`

const TooltipContent = styled.div<{ isVisible: boolean }>`
    visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
    position: absolute;
    z-index: 100;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    font-size: 14px;
    white-space: nowrap;

    &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #333 transparent transparent transparent;
    }
`

interface TooltipProps {
    content: string
    children: React.ReactNode
}

export const Tooltip: React.FC<TooltipProps> = ({ content, children }) => {
    const [isVisible, setIsVisible] = useState(false)

    return (
        <TooltipWrapper
            onMouseEnter={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
        >
            {children}
            <TooltipContent isVisible={isVisible}>{content}</TooltipContent>
        </TooltipWrapper>
    )
}
