/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { MdOutlineAddShoppingCart } from 'react-icons/md'
import { FiPlus, FiX } from 'react-icons/fi'
import { ControlButton } from '../../../components/ControlButton'
import { SelectInput } from '../../../components/SelectInput'
import { TitleDescription } from '../../../components/TitleDescription'
import { useCompany } from '../../../hooks/use-company'
import { ReturnApprovalLevelLabel } from '../../../utils/users/functions'
import { DropDownPolitic } from '../headerPolicy'
import { SinglePolicieContainer } from './styles'
import { toolTips } from '../data/labels'
import { ExpensePolicy } from '../../../interfaces/traveler-clearance/traveler-clearance/polices/expense-policy'
import { FilterInput } from '../../../components/FilterInput'
import { Tooltip } from '../../../components/Tooltip'

interface Props {
    invoiceExpenses: ExpensePolicy['invoice']
    forbiddenWords: ExpensePolicy['forbiddenWords']
}

export function InvoiceExpenses({ invoiceExpenses, forbiddenWords }: Props) {
    // hooks
    const { editTravelerClearance, selectTravelerClearance, company } =
        useCompany()
    const [invExpense, setInvExpense] =
        useState<ExpensePolicy['invoice']>(invoiceExpenses)
    const [invForbiddenWords, setInvForbiddenWords] =
        useState<ExpensePolicy['forbiddenWords']>(forbiddenWords)
    const [words, setWords] = useState<string[]>(forbiddenWords?.words || [])
    const [wordsInput, setWordsInput] = useState('')

    const approvalLevelOptions: {
        label: string
        value: number
    }[] = new Array(company?.maxApprovalLevel || 1).fill(1).map((_, i) => {
        return {
            label: `Nivel ${i + 1}`,
            value: i + 1,
        }
    })

    useEffect(() => {
        setInvExpense(invoiceExpenses)
        setInvForbiddenWords(forbiddenWords)
    }, [selectTravelerClearance])

    return (
        <DropDownPolitic
            title="Despesas"
            description="Despesas que fogem das regras configuradas abaixo serão consideradas como fora da política de viagens."
            icon={MdOutlineAddShoppingCart}
            callback={async () => {
                await editTravelerClearance(selectTravelerClearance!.id, {
                    expense: {
                        invoice: invExpense,
                        forbiddenWords: {
                            enabled: invForbiddenWords?.enabled ?? false,
                            words,
                        },
                    },
                })
            }}
        >
            <SinglePolicieContainer>
                <TitleDescription title="Permitir fazer solicitações de despesas?" />
                <div className="inputs">
                    <ControlButton
                        id="invExpense.productEnabled"
                        type="SWITCH"
                        isPressed={invExpense.enabled}
                        callback={() => {
                            setInvExpense((prevState: any) => {
                                return {
                                    ...prevState,
                                    enabled: !prevState.enabled,
                                }
                            })
                        }}
                    />
                </div>
            </SinglePolicieContainer>
            <SinglePolicieContainer>
                <TitleDescription
                    title="Fluxo de aprovação"
                    description="Defina quando uma despensa precisa ser aprovada e quantos níveis de aprovação devem existir."
                    info={toolTips.approvalFlow}
                />
                <div className="inputs">
                    <div className="label-input">
                        <p>Defina a quantidade de níveis</p>
                        <SelectInput
                            name="invoiceExpense2"
                            value={{
                                label: ReturnApprovalLevelLabel(
                                    invExpense.approvalFlow
                                        .requiredApprovalLevel
                                ),
                                value: invExpense.approvalFlow
                                    .requiredApprovalLevel,
                            }}
                            options={approvalLevelOptions}
                            onChange={(e) => {
                                if (e) {
                                    setInvExpense((prevState) => {
                                        return {
                                            ...prevState,
                                            approvalFlow: {
                                                ...prevState.approvalFlow,
                                                requiredApprovalLevel: e.value,
                                            },
                                        }
                                    })
                                }
                            }}
                        />
                    </div>
                </div>
            </SinglePolicieContainer>
            <SinglePolicieContainer>
                <TitleDescription title="Bloquear criação de despesas com NFCE repetido" />
                <div className="inputs">
                    <ControlButton
                        id="invExpense.nonRepeatingNfce"
                        type="SWITCH"
                        isPressed={invExpense.nonRepeatingNfce}
                        callback={() => {
                            setInvExpense((prevState: any) => {
                                return {
                                    ...prevState,
                                    nonRepeatingNfce:
                                        !prevState.nonRepeatingNfce,
                                }
                            })
                        }}
                    />
                </div>
            </SinglePolicieContainer>
            <SinglePolicieContainer>
                <TitleDescription title="Ativar palavras proibidas" />
                <div className="inputs">
                    <ControlButton
                        id="forbiddenWords?.enabled"
                        type="SWITCH"
                        isPressed={invForbiddenWords?.enabled ?? false}
                        callback={() => {
                            setInvForbiddenWords((prevState: any) => {
                                return {
                                    ...prevState,
                                    enabled: !prevState.enabled,
                                }
                            })
                        }}
                    />
                </div>
            </SinglePolicieContainer>
            <SinglePolicieContainer>
                <TitleDescription
                    title="Palavras proibidas"
                    description="Despesas que contiverem as palavras proibidas serão marcadas como fora da política."
                    // info={toolTips.approvalFlow}
                />
                <div className="inputs">
                    <div className="label-input">
                        <FilterInput
                            label="Adicionar palavra"
                            name="forbiddenWords"
                            icon={FiPlus}
                            value={wordsInput}
                            onChange={(e) => setWordsInput(e.target.value)}
                        />
                        <button
                            type="button"
                            className="add-word"
                            onClick={() => {
                                setWords((prev) => {
                                    return [...prev, wordsInput]
                                })
                                setWordsInput('')
                            }}
                        >
                            Adicionar
                        </button>
                    </div>
                    <div className="forbidden-words">
                        {words.map((word, index) => (
                            <div key={index + word} className="word">
                                <Tooltip content={word}>
                                    <p>{word}</p>
                                </Tooltip>
                                <button
                                    className="word-remover"
                                    type="button"
                                    onClick={() => {
                                        setWords((prev) => {
                                            return prev.filter(
                                                (w) => w !== word
                                            )
                                        })
                                    }}
                                >
                                    <FiX />
                                </button>
                            </div>
                        ))}
                        {words.length === 0 && (
                            <p className="f14-500-gray">
                                Nenhuma palavra adicionada
                            </p>
                        )}
                    </div>
                </div>
            </SinglePolicieContainer>
        </DropDownPolitic>
    )
}
