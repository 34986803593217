/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useRef } from 'react'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { v4 } from 'uuid'
import { useCompany } from '../../hooks/use-company'
import { DynamicSupplierProps } from '.'
import { Flex } from './styles'
import { Input } from '../../UnformInputs/Input'
import { getValidationErrors } from '../../utils/getValidationErrors'
import { Button } from '../../components/Button'
import { Supplier } from '../../interfaces/supplier'
import SelectInput from '../../UnformInputs/SelectInput'

export function DynamicSupplierForm({
    credentialFieldRules,
    busyBtn,
    credentialId,
    isEditMode,
    onSend,
    resetEditation,
    supplierId,
}: DynamicSupplierProps) {
    const {
        createSupplierCredential,
        editSupplierCredential,
        getDecodedCredential,
    } = useCompany()
    const formRef = useRef<FormHandles>(null)

    async function handleGetDecodedCredential() {
        if (!credentialId) return
        const response = await getDecodedCredential(credentialId)
        formRef.current?.setData({
            ...response,
        })
    }

    useEffect(() => {
        handleGetDecodedCredential()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [credentialId])

    const buildYupSchema = (fieldRules: Supplier['credentialFieldRules']) => {
        const shape: { [key: string]: any } = {}

        fieldRules.forEach((field) => {
            if (field.isRequired) {
                let validator = Yup.string().required(
                    `Insira um ${field.label}`
                )
                if (field.regex) {
                    validator = validator.matches(
                        new RegExp(field.regex),
                        `${field.label} is not valid`
                    )
                }
                shape[field.fieldName] = validator
            }
        })

        return Yup.object().shape(shape)
    }

    async function handleDynamicData(data: any) {
        onSend(true)
        try {
            formRef.current?.setErrors({})
            const schema = buildYupSchema(credentialFieldRules)
            await schema.validate(data, {
                abortEarly: false,
            })
            const hash = Buffer.from(JSON.stringify(data)).toString('base64')
            if (isEditMode && credentialId) {
                const response = await editSupplierCredential({
                    id: supplierId,
                    data: { hash },
                    credentialId,
                })
                if (response) {
                    resetEditation()
                }
                onSend(false)
            } else {
                const response = await createSupplierCredential({
                    id: supplierId,
                    hash,
                })
                if (response) {
                    resetEditation()
                }
                onSend(false)
            }
        } catch (error) {
            onSend(false)
            toast.info(
                'Verifique se você preencheu todos os campos corretamente'
            )
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error)
                formRef.current?.setErrors(errors)
            }
        }
    }
    return (
        <Form
            ref={formRef}
            onSubmit={handleDynamicData}
            noValidate
            autoComplete="off"
        >
            <Flex>
                {credentialFieldRules.map((field, index) => {
                    if (field.fieldType === 'input') {
                        return (
                            <Input
                                autoComplete={v4()}
                                key={index}
                                label={field.label}
                                name={field.fieldName}
                                type={
                                    field.inputType?.toLocaleLowerCase() ??
                                    'text'
                                }
                            />
                        )
                    }
                    if (field.fieldType === 'select') {
                        return (
                            <SelectInput
                                key={index}
                                autoComplete={v4()}
                                name={field.fieldName}
                                options={field.options!}
                                placeholder={field.label}
                            />
                        )
                    }
                    return null
                })}
            </Flex>
            <div className="modal-confirm-btn">
                <Button
                    color="PRIMARY"
                    buttonType="FILLED"
                    text="Salvar"
                    busy={busyBtn}
                    type="submit"
                />
            </div>
        </Form>
    )
}
