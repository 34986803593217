/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react'
import { Pagination } from '@material-ui/lab'
import { useAutosave } from 'react-autosave'
import { MdSearch } from 'react-icons/md'
import { ClipLoader } from 'react-spinners'
import { AdvanceMoneyContainer } from './styles'
import { SubTabs } from '../../../components/SubTabs'
import { useExpenses } from '../../../hooks/use-expenses'
import { PaginationContainer } from '../../Reports/styles'
import AdvancedMoneyCard from '../../../components/AdvancedMoneyCard'
import AdvanceMoneyModal from '../../../modals/AdvanceMoney'
import HistoryModal from '../../../modals/HistoryModal'
import UseHistoryModal from '../../../modals/UseHistoryModal'
import { useCompany } from '../../../hooks/use-company'
import { FilterInput } from '../../../components/FilterInput'
import { FilterInputBtn } from '../../../components/FilterInputBtn'
import { ChoseOnneMemberModal } from '../../../modals/ChoseOnneMemberModal'
import AdvanceMoneyApprovalStagesModal from '../../../modals/AdvanceMoneyApprovalStagesModal'

export default function Expenses() {
    const { company, members, getMembers } = useCompany()
    const {
        openAdvancedMoneyModal,
        openAdvancedMoneyModalHandler,
        getAdvancedMoney,
        advancedMoney,
        count,
        isHistoryModalOpen,
        isUseHistoryModal,
        openUseHistoryModal,
        openHistoryModal,
        isApprovalStagesModalOpen,
        openApprovalStagesModal,
    } = useExpenses()
    const [tabValue, setTabValue] = useState<
        'CONFIRMED' | 'PAID' | 'COMPLETED' | 'PENDING_APPROVAL'
    >('CONFIRMED')

    const [pageSelected, setPageSelected] = useState<number>(1)
    const [busy, setBusy] = useState(false)
    const [creatorId, setCreatorId] = useState<string | undefined>()
    const [numberIdentifier, setNumberIdentifier] = useState('')
    const [openCreatorModal, setOpenCreatorModal] = useState<boolean>(false)

    function numberOfPages(countNum: number) {
        return Math.ceil(countNum / 10)
    }

    function changeTabValue(
        type: 'CONFIRMED' | 'PAID' | 'COMPLETED' | 'PENDING_APPROVAL'
    ) {
        setTabValue(type)
    }

    useEffect(() => {
        if (company) {
            ;(async () => {
                await getMembers(company.id)
            })()
        }
    }, [])

    useEffect(() => {
        setBusy(true)
        ;(async () => {
            await getAdvancedMoney(
                tabValue,
                creatorId,
                numberIdentifier,
                (pageSelected - 1) * 10,
                10
            )
            setBusy(false)
        })()
    }, [tabValue, pageSelected, creatorId])

    useEffect(() => {
        setPageSelected(1)
    }, [tabValue])

    useAutosave({
        data: numberIdentifier,
        onSave: async () => {
            if (company) {
                setPageSelected(1)
                setBusy(true)
                const data = await getAdvancedMoney(
                    tabValue,
                    creatorId,
                    numberIdentifier,
                    (pageSelected - 1) * 10,
                    10
                )
                setBusy(false)
            }
        },
        interval: 500,
    })

    return (
        <AdvanceMoneyContainer>
            <div className="title">
                <h2 className="f22-700-dark">Adiantamentos</h2>
                <p className="f16-500-gray">
                    Concilie e confira os reembolsos de adiantamentos realizados
                    pelos seus viajantes.
                </p>
            </div>
            <div className="tabs">
                <SubTabs
                    direction="HORIZONTAL"
                    changeTab={changeTabValue}
                    value={tabValue}
                    tabs={[
                        {
                            label: 'Pagar o viajante o adiantamento',
                            id: 'CONFIRMED',
                        },
                        {
                            label: 'Aguardando o viajante prestar conta',
                            id: 'PAID',
                        },
                        {
                            label: 'Justificados e devolvidos',
                            id: 'COMPLETED',
                        },
                        {
                            label: 'Aguardando aprovação',
                            id: 'PENDING_APPROVAL',
                        },
                    ]}
                />
            </div>
            <div className="filters">
                <div>
                    <h2 className="f14-500-gray normal-label">
                        Número identificador
                    </h2>
                    <FilterInput
                        label="Pesquisar..."
                        name="search"
                        icon={MdSearch}
                        value={numberIdentifier}
                        onChange={(e) => setNumberIdentifier(e.target.value)}
                    />
                </div>
                <FilterInputBtn
                    label="Criador do item"
                    normalLabel
                    placeHolder="Não especificado"
                    value={
                        members.find((user) => user.id === creatorId)
                            ? `${
                                  members.find((user) => user.id === creatorId)
                                      ?.firstName
                              } ${
                                  members.find((user) => user.id === creatorId)
                                      ?.lastName
                              }`
                            : undefined
                    }
                    callback={() => {
                        setOpenCreatorModal(true)
                    }}
                />
            </div>
            <div className="grid">
                {busy && <ClipLoader />}

                {!busy &&
                    advancedMoney.map((advanced) => (
                        <AdvancedMoneyCard advancedMoney={advanced} />
                    ))}
                {advancedMoney.length === 0 && (
                    <p className="f16-500-gray">
                        Sem adiantamentos cadastrados
                    </p>
                )}
            </div>
            <div className="pagination">
                <PaginationContainer>
                    <Pagination
                        count={numberOfPages(count || 0)}
                        color="primary"
                        variant="outlined"
                        page={pageSelected}
                        shape="rounded"
                        size="medium"
                        onChange={(
                            event: any,
                            page: React.SetStateAction<number>
                        ) => {
                            setPageSelected(page)
                        }}
                    />
                </PaginationContainer>
            </div>
            {!!openAdvancedMoneyModal && (
                <AdvanceMoneyModal
                    isOpen={!!openAdvancedMoneyModal}
                    advanceMoney={openAdvancedMoneyModal}
                    onRequestClose={() =>
                        openAdvancedMoneyModalHandler(undefined)
                    }
                />
            )}
            {!!isHistoryModalOpen && (
                <HistoryModal
                    isOpen={!!isHistoryModalOpen}
                    onRequestClose={() => openHistoryModal(undefined)}
                    history={isHistoryModalOpen}
                />
            )}
            {!!isUseHistoryModal && (
                <UseHistoryModal
                    isOpen={!!isUseHistoryModal}
                    onRequestClose={() => openUseHistoryModal(undefined)}
                    advanceMoney={isUseHistoryModal}
                />
            )}
            {!!isApprovalStagesModalOpen && (
                <AdvanceMoneyApprovalStagesModal
                    isOpen={!!isApprovalStagesModalOpen}
                    onRequestClose={() => openApprovalStagesModal(undefined)}
                    approvalStages={isApprovalStagesModalOpen}
                />
            )}
            {openCreatorModal && (
                <ChoseOnneMemberModal
                    isOpen={openCreatorModal}
                    onRequestClose={() => setOpenCreatorModal(false)}
                    users={members.map((user) => {
                        return {
                            id: user.id,
                            label: `${user.firstName} ${user.lastName}`,
                            url: user.photo?.url,
                        }
                    })}
                    selected={creatorId}
                    callback={(id) => {
                        if (id === creatorId) {
                            setCreatorId(undefined)
                            setOpenCreatorModal(false)
                            return
                        }
                        setCreatorId(id)
                        setOpenCreatorModal(false)
                    }}
                />
            )}
        </AdvanceMoneyContainer>
    )
}
