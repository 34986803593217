import styled from 'styled-components'

export const ExpensesModalContainer = styled.div`
    max-height: 70vh;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 0.5rem;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        border-radius: 0.5rem;
        background: #edeff2;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        border-radius: 0.5rem;
        background: #c8cacd;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #c8cacd;
    }
    .history-btn {
        border: none;
        background-color: inherit;
        color: var(--color-primary);
        font-weight: 700;
        font-size: 1.4rem;
        &:hover {
            cursor: pointer;
        }
    }
    .header {
        background-color: #fff;
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        padding: 3.6rem 2.1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .description {
            display: flex;
            align-items: center;
            gap: 2rem;
        }
        .history-btn {
            border: none;
            background-color: inherit;
            color: var(--color-primary);
            font-weight: 700;
            font-size: 1.4rem;
            &:hover {
                cursor: pointer;
            }
        }
        margin-bottom: 1.5rem;
    }
    .content {
        width: 100%;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
        &-block {
            margin-bottom: 1rem;
            box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
            border-radius: 8px;
            width: 100%;
            padding: 1.5rem;
            background-color: #fff;
            .italic {
                font-style: italic;
            }

            .container {
                .items {
                    display: grid;
                    grid-template-columns: 2fr 1fr 1fr;
                    padding: 2rem 0;
                    border-bottom: 1px solid #f4f4f4;
                    & > p {
                        padding: 0 1rem;
                    }
                }
                .label-value {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 2rem 0;
                    border-bottom: 1px solid #f4f4f4;
                    & > p {
                        padding: 0 1rem;
                    }
                }
            }
        }
        margin-bottom: 1.5rem;
    }
    .comments {
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        width: 100%;
        padding: 1.5rem;
        background-color: #fff;
        .description {
            min-height: 8rem;
            margin-top: 1.5rem;
            padding: 1.5rem;
            border-radius: 8px;
            border: 1px solid #c8cacd;
            background-color: #edeff2;
        }
    }
`
