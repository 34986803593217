/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react'
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns'
// eslint-disable-next-line import/no-duplicates
import ptLocale from 'date-fns/locale/pt-BR'
import { Pagination } from '@material-ui/lab'
import { ClipLoader } from 'react-spinners'
import { Header } from '../../components/Header'
import { PaginationContainer, ReportsContainer, ReportsContent } from './styles'
import { PillTabs } from '../../components/PillTabs'
// import { Button } from '../../components/Button'
import { Table } from '../../utils/table'
import { SingleReportData } from '../../components/SingleReportData'
import { SelectInput } from '../../components/SelectInput'
import { ReportFilter } from '../../components/ReportFilter'
import { useCompany } from '../../hooks/use-company'
import { ExportReport } from '../../modals/ExportReport'
import { useDashboard } from '../../hooks/useDashboard'
import { Button } from '../../components/Button'

export function ReportsPage() {
    const { getManagementInfo, managementInfo } = useCompany()
    const {
        getReportData,
        reportFilter,
        carReport,
        flightReport,
        expensesReport,
        generalReport,
        hotelReport,
        benvReport,
        count,
        downloadExpensesReportData,
        downloadAttachments,
    } = useDashboard()

    const [selectedTab, setSelectedTab] = useState<
        'GENERAL' | 'FLIGHT' | 'HOTEL' | 'CAR' | 'EXPENSES' | 'BENV'
    >('GENERAL')

    const [busy, setBusy] = useState(false)

    const [openExportReport, setOpenExportReport] = useState<
        'GENERAL' | 'FLIGHT' | 'HOTEL' | 'CAR' | 'EXPENSES' | 'BENV' | undefined
    >(undefined)
    const [limit, setLimit] = useState<{ label: string; value: number }>({
        label: 'Resultado por página: 10',
        value: 10,
    })
    const [pageSelected, setPageSelected] = useState<number>(1)
    const [downloadLoading, setDownloadLoading] = useState(false)

    function numberOfPages(countNum: number) {
        return Math.ceil(countNum / limit.value)
    }

    const tabs = [
        { label: 'Geral', id: 'GENERAL' },
        { label: 'Aéreo', id: 'FLIGHT' },
        { label: 'Hotel', id: 'HOTEL' },
        { label: 'Carro', id: 'CAR' },
        { label: 'Despesas', id: 'EXPENSES' },
        { label: 'Bilhetes não voados', id: 'BENV' },
    ]

    useEffect(() => {
        setBusy(true)
        ;(async () => {
            await getManagementInfo()
            setBusy(false)
        })()
    }, [])

    useEffect(() => {
        setPageSelected(1)
    }, [selectedTab])

    useEffect(() => {
        ;(async () => {
            setBusy(true)
            await getReportData(
                selectedTab,
                reportFilter,
                pageSelected,
                limit.value
            )
            setBusy(false)
        })()
    }, [selectedTab, reportFilter, pageSelected, limit])

    function returnAppropriatedTable() {
        if (count === 0) {
            return (
                <div className="no-data">
                    <h1 className="results">Sem resultados</h1>
                </div>
            )
        }
        if (selectedTab === 'GENERAL') {
            return (
                <Table>
                    <div
                        className="table-header"
                        style={{
                            gridTemplateColumns: '4fr 2fr 2fr 3fr 2fr 3fr',
                        }}
                    >
                        <h1 className="center">Viajante</h1>
                        <h1 className="center">Data de emissão</h1>
                        <h1 className="center">Tipo da reserva</h1>
                        <h1 className="center">Reserva</h1>
                        <h1 className="center">Status</h1>
                        <h1 className="center">Destino</h1>
                    </div>
                    <div className="table-content">
                        {!busy ? (
                            generalReport.map((general) => {
                                return (
                                    <SingleReportData generalReport={general} />
                                )
                            })
                        ) : (
                            <ClipLoader />
                        )}
                    </div>
                </Table>
            )
        }
        if (selectedTab === 'FLIGHT') {
            return (
                <Table>
                    <div
                        className="table-header"
                        style={{
                            gridTemplateColumns: '4fr 2fr 2fr 3fr 2fr 3fr',
                        }}
                    >
                        <h1 className="center">Viajante</h1>
                        <h1 className="center">Data de emissão</h1>
                        <h1 className="center">Data da viagem</h1>
                        <h1 className="center">Localizador</h1>
                        <h1 className="center">Destinos</h1>
                        <h1 className="center">Fornecedor</h1>
                    </div>
                    <div className="table-content">
                        {!busy ? (
                            flightReport.map((flight) => {
                                return (
                                    <SingleReportData flightReport={flight} />
                                )
                            })
                        ) : (
                            <ClipLoader />
                        )}
                    </div>
                </Table>
            )
        }
        if (selectedTab === 'HOTEL') {
            return (
                <Table>
                    <div
                        className="table-header"
                        style={{
                            gridTemplateColumns: '4fr 2fr 2fr 3fr 2fr 3fr',
                        }}
                    >
                        <h1 className="center">Viajante</h1>
                        <h1 className="center">Data de emissão</h1>
                        <h1 className="center">Reserva</h1>
                        <h1 className="center">Destino</h1>
                        <h1 className="center">Checkin</h1>
                        <h1 className="center">Checkout</h1>
                    </div>
                    <div className="table-content">
                        {!busy ? (
                            hotelReport.map((hotel) => {
                                return <SingleReportData hotelReport={hotel} />
                            })
                        ) : (
                            <ClipLoader />
                        )}
                    </div>
                </Table>
            )
        }
        if (selectedTab === 'CAR') {
            return (
                <Table>
                    <div
                        className="table-header"
                        style={{
                            gridTemplateColumns: '4fr 2fr 3fr 3fr 2fr 2fr',
                        }}
                    >
                        <h1 className="center">Viajante</h1>
                        <h1 className="center">Data de emissão</h1>
                        <h1 className="center">Reserva</h1>
                        <h1 className="center">Localização</h1>
                        <h1 className="center">Retirada</h1>
                        <h1 className="center">Devolução</h1>
                    </div>
                    <div className="table-content">
                        {!busy ? (
                            carReport.map((car) => {
                                return <SingleReportData carReport={car} />
                            })
                        ) : (
                            <ClipLoader />
                        )}
                    </div>
                </Table>
            )
        }
        if (selectedTab === 'EXPENSES') {
            return (
                <Table>
                    <div
                        className="table-header"
                        style={{
                            gridTemplateColumns: '1fr 4fr 3fr 2fr 3fr 2fr',
                        }}
                    >
                        <h1 className="center">ID</h1>
                        <h1 className="center">Criado por</h1>
                        <h1 className="center">Data</h1>
                        <h1 className="center">Categoria</h1>
                        <h1 className="center">Status</h1>
                        <h1 className="center">Valor</h1>
                    </div>
                    <div className="table-content">
                        {!busy ? (
                            expensesReport?.map((expenses) => {
                                return (
                                    <SingleReportData
                                        expensesReport={expenses}
                                    />
                                )
                            })
                        ) : (
                            <ClipLoader />
                        )}
                    </div>
                </Table>
            )
        }
        if (selectedTab === 'BENV') {
            return (
                <Table>
                    <div
                        className="table-header"
                        style={{
                            gridTemplateColumns: '4fr 2fr 2fr 3fr 2fr 3fr',
                        }}
                    >
                        <h1 className="center">Número do bilhete</h1>
                        <h1 className="center">Localizador</h1>
                        <h1 className="center">Status</h1>
                        <h1 className="center">Crédito</h1>
                        <h1 className="center">Penalidade</h1>
                        <h1 className="center">Expiração</h1>
                    </div>
                    <div className="table-content">
                        {!busy ? (
                            benvReport.map((benv) => {
                                return <SingleReportData benvReport={benv} />
                            })
                        ) : (
                            <ClipLoader />
                        )}
                    </div>
                </Table>
            )
        }

        return null
    }

    return (
        <ReportsContainer>
            <Header header="REPORTS" />
            <ReportsContent>
                <div className="tabs-container">
                    <PillTabs
                        pillTabs={tabs}
                        value={selectedTab}
                        theme="LIGHT"
                        changeTab={(
                            id:
                                | 'GENERAL'
                                | 'FLIGHT'
                                | 'HOTEL'
                                | 'CAR'
                                | 'EXPENSES'
                                | 'BENV'
                        ) => setSelectedTab(id)}
                    />
                </div>
                {selectedTab !== 'BENV' && (
                    <div className="filter">
                        <ReportFilter
                            managementInfos={managementInfo}
                            showManagementInfos={selectedTab !== 'EXPENSES'}
                        />
                    </div>
                )}
                <div className="divider" />
                <div className="title">
                    <h1 className="f18-700-dark">Resumo</h1>
                </div>
                <p className="f14-500-gray description">
                    {`Viagens e despesas emitidas de ${
                        format(
                            new Date(reportFilter?.period?.startDate || ''),
                            `dd' ' MMM', 'YYY`,
                            {
                                locale: ptLocale,
                            }
                        ) || ''
                    } à ${
                        format(
                            new Date(reportFilter?.period?.endDate || ''),
                            `dd' ' MMM', 'YYY`,
                            {
                                locale: ptLocale,
                            }
                        ) || ''
                    } |
                    ${count || 0} itens`}
                </p>
                <div className="buttons-row">
                    <div className="selector">
                        <SelectInput
                            name="pagination"
                            value={limit}
                            options={[
                                {
                                    label: 'Resultado por página: 10',
                                    value: 10,
                                },
                                {
                                    label: 'Resultado por página: 50',
                                    value: 50,
                                },
                            ]}
                            onChange={(e) => {
                                if (e)
                                    setLimit({ value: e.value, label: e.label })
                            }}
                        />
                    </div>
                    <div className="buttons">
                        {selectedTab === 'EXPENSES' && (
                            <Button
                                buttonType="BORDERED"
                                color="PRIMARY"
                                text="Baixar anexos"
                                busy={downloadLoading}
                                onClick={async () => {
                                    setDownloadLoading(true)
                                    if (selectedTab !== 'EXPENSES') {
                                        setDownloadLoading(false)
                                        return setOpenExportReport(selectedTab)
                                    }
                                    await downloadAttachments()
                                    setDownloadLoading(false)

                                    return undefined
                                }}
                            />
                        )}
                        <Button
                            buttonType="FILLED"
                            color="PRIMARY"
                            text="Baixar relatório"
                            onClick={async () => {
                                if (selectedTab !== 'EXPENSES') {
                                    return setOpenExportReport(selectedTab)
                                }
                                await downloadExpensesReportData()
                                return undefined
                            }}
                        />
                    </div>
                </div>
                <div className="table-data">{returnAppropriatedTable()}</div>

                <div className="pagination">
                    <PaginationContainer>
                        <Pagination
                            count={numberOfPages(count || 0)}
                            color="primary"
                            variant="outlined"
                            page={pageSelected}
                            shape="rounded"
                            size="medium"
                            siblingCount={1}
                            onChange={(
                                event: any,
                                page: React.SetStateAction<number>
                            ) => {
                                setPageSelected(page)
                            }}
                        />
                    </PaginationContainer>
                </div>
            </ReportsContent>
            {openExportReport && (
                <ExportReport
                    isOpen={!!openExportReport}
                    onRequestClose={() => setOpenExportReport(undefined)}
                    type={openExportReport}
                />
            )}
        </ReportsContainer>
    )
}
