/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import ModalContainer from 'react-modal'
import { ExpensesModalContainer } from './styles'
import { Expenses } from '../../interfaces/expenses'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
    ocrData: Expenses['ocrData']
}

export default function OcrDataModal({
    isOpen,
    onRequestClose,
    ocrData,
}: Props) {
    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-history"
        >
            <ExpensesModalContainer>
                <div className="header">
                    <h1 className="f18-700-dark">Dados de ocr</h1>
                </div>
                <div className="content-block">
                    <div className="container">
                        <p className="f16-500-gray italic">Emitente</p>
                        <div className="label-value">
                            <p className="f16-500-gray">Razão social</p>
                            <p className="f16-500-gray">
                                {ocrData.issuer.name || '-'}
                            </p>
                        </div>
                        <div className="label-value">
                            <p className="f16-500-gray">CNPJ</p>
                            <p className="f16-500-gray">
                                {ocrData.issuer.cnpj || '-'}
                            </p>
                        </div>
                        <div className="label-value">
                            <p className="f16-500-gray">Endereço</p>
                            <p className="f16-500-gray">
                                {ocrData.issuer.address || '-'}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="content-block">
                    <div className="container">
                        <p className="f16-500-gray italic">Produtos</p>
                        {ocrData.items.map((product, index) => (
                            <div key={index} className="items">
                                <p className="f16-500-gray name">
                                    {product.name}
                                </p>
                                <p className="f16-500-gray">
                                    {product.quantity}
                                </p>
                                <p className="f16-500-gray">
                                    R${product.total}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="content-block">
                    <div className="container">
                        <p className="f16-500-gray italic">Pagamento</p>
                        <div className="label-value">
                            <p className="f16-500-gray">Forma de pagamento</p>
                            <p className="f16-500-gray">
                                {ocrData.payment_types[0]?.type || '-'}
                            </p>
                        </div>
                        <div className="label-value">
                            <p className="f16-500-gray">Recibo</p>
                            <p className="f16-500-dark">
                                {ocrData.receipt_url ? (
                                    <a
                                        href={ocrData.receipt_url}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <button
                                            type="button"
                                            className="history-btn"
                                        >
                                            Ver anexo
                                        </button>
                                    </a>
                                ) : (
                                    '-'
                                )}
                            </p>
                        </div>
                        <div className="label-value">
                            <p className="f16-500-gray">nfce</p>
                            <p className="f16-500-gray nfce">
                                {ocrData.nfce ?? '-'}
                            </p>
                        </div>
                        <div className="label-value">
                            <p className="f16-500-gray">Valor total</p>
                            <p className="f16-500-gray">R${ocrData.total}</p>
                        </div>
                    </div>
                </div>
            </ExpensesModalContainer>
        </ModalContainer>
    )
}
