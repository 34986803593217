/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-duplicates */
import React, { useState } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'

import { v4 } from 'uuid'
import { FilterContainer, PeriodDropDown, Selector } from './styles'
import { DatePicker } from '../DatePicker'
import { FullManagementInfo } from '../../entities/full-management-info'
import { usePayment } from '../../hooks/use-payment'
import { ProductTypes } from '../../entities/payment-filter'
import { SelectInput } from '../SelectInput'
import { ChoseOnneMemberModal } from '../../modals/ChoseOnneMemberModal'
import { User } from '../../entities/user'
import { FilterInputBtn } from '../FilterInputBtn'
import { ControlButton } from '../ControlButton'

interface Props {
    managementInfos: FullManagementInfo[]
    members: User[]
}

export function PaymentFilter({ managementInfos, members }: Props) {
    const { handlePaymentFilter, paymentFilter } = usePayment()

    const [selectedPeriod, setSelectedPeriod] = useState(false)
    const [openSelecteCustomData, setOpenSelectCustomData] = useState(false)
    const [selectedDrop, setSelectedDrop] = useState<string | undefined>(
        undefined
    )

    const [openCreatorModal, setOpenCreatorModal] = useState(false)
    const [accessibleByUser, setAccessibleByUser] = useState<
        string | undefined
    >()

    const products = [ProductTypes.FLIGHT, ProductTypes.HOTEL, ProductTypes.CAR]

    function existThisManagementinfo(id: string) {
        if (paymentFilter?.managementInfos) {
            return paymentFilter?.managementInfos.some(
                (managementInfo) => managementInfo.id === id
            )
        }
        return false
    }

    function hasOptionsAdded(Mid: string) {
        const management = paymentFilter?.managementInfos.find(
            (m) => m.id === Mid
        )
        if (management) {
            return management.options.length > 0
        }
        return false
    }

    function optionsAdded(Mid: string) {
        const managementFull = managementInfos.find((m) => m.id === Mid)
        const filteredManagement = paymentFilter?.managementInfos?.find(
            (m) => m.id === Mid
        )
        if (managementFull && filteredManagement) {
            return managementFull.options
                .filter((opt) => filteredManagement.options.includes(opt.id))
                .map((opt) => opt.label)
        }
        return []
    }

    function addOptionToManagementInfo(mId: string, optionId: string) {
        if (paymentFilter?.managementInfos) {
            const management = paymentFilter?.managementInfos.find(
                (m) => m.id === mId
            )
            if (management) {
                management.options.push(optionId)
            } else {
                paymentFilter.managementInfos.push({
                    id: mId,
                    options: [optionId],
                })
            }
        }
        return paymentFilter
    }

    function removeOptionFromManagementInfo(mId: string, optionId: string) {
        if (paymentFilter?.managementInfos) {
            const management = paymentFilter?.managementInfos.find(
                (m) => m.id === mId
            )
            if (management) {
                management.options = management.options.filter(
                    (opt) => opt !== optionId
                )
            }
            if (management?.options.length === 0) {
                paymentFilter.managementInfos =
                    paymentFilter.managementInfos.filter((m) => m.id !== mId)
            }
        }
        return paymentFilter
    }

    function existThisOption(Mid: string, id: string) {
        if (paymentFilter?.managementInfos) {
            const management = paymentFilter?.managementInfos.find(
                (m) => m.id === Mid
            )
            if (management) {
                return !!management.options.find((opt) => opt === id)
            }
        }
        return false
    }

    function expenseOptionSelected() {
        if (paymentFilter?.canBeUsedOnExpenses === false) {
            return {
                label: 'Não',
                value: false,
            }
        }

        if (paymentFilter?.canBeUsedOnExpenses) {
            return {
                label: 'Sim',
                value: true,
            }
        }

        return {
            label: 'Usável em despesas',
            value: undefined,
        }
    }

    function productLabel(type: ProductTypes | undefined) {
        if (type === 'FLIGHT') {
            return 'Voo'
        }
        if (type === 'HOTEL') {
            return 'Hotel'
        }
        if (type === 'CAR') {
            return 'Carro'
        }

        return 'produto customizado'
    }

    return (
        <FilterContainer>
            <div className="second-row">
                <FilterInputBtn
                    label="Filtro por usuário:"
                    placeHolder="Filtro por usuário"
                    value={
                        members.find((user) => user.id === accessibleByUser)
                            ? `${
                                  members.find(
                                      (user) => user.id === accessibleByUser
                                  )?.firstName
                              } ${
                                  members.find(
                                      (user) => user.id === accessibleByUser
                                  )?.lastName
                              }`
                            : undefined
                    }
                    callback={() => {
                        setOpenCreatorModal(true)
                    }}
                />
                {managementInfos.map((management) => {
                    return (
                        <PeriodDropDown>
                            <label
                                htmlFor={management.id}
                                className={`selector-label ${
                                    existThisManagementinfo(management.id) &&
                                    hasOptionsAdded(management.id)
                                        ? 'bordered'
                                        : ''
                                }`}
                            >
                                <input
                                    type="checkbox"
                                    name={management.id}
                                    checked={selectedDrop === management.id}
                                    id={management.id}
                                    className="selector-input"
                                    onChange={(e) =>
                                        setSelectedDrop(e.target.id)
                                    }
                                />
                                {existThisManagementinfo(management.id) &&
                                hasOptionsAdded(management.id) ? (
                                    <h4>
                                        {optionsAdded(management.id).join(', ')}
                                    </h4>
                                ) : (
                                    <p>{management.label}</p>
                                )}
                                {existThisManagementinfo(management.id) &&
                                hasOptionsAdded(management.id) ? (
                                    <AiFillCloseCircle
                                        style={{
                                            height: '1.6rem',
                                            width: '1.6rem',
                                        }}
                                        onClick={() => {
                                            if (
                                                hasOptionsAdded(management.id)
                                            ) {
                                                handlePaymentFilter({
                                                    ...paymentFilter,
                                                    managementInfos:
                                                        paymentFilter?.managementInfos?.filter(
                                                            (m) =>
                                                                m.id !==
                                                                management.id
                                                        ),
                                                })
                                            }
                                        }}
                                    />
                                ) : (
                                    <></>
                                )}
                                <div className="selectors">
                                    <div className="title-dropdown">
                                        <h1>Opções</h1>
                                        <div className="btns">
                                            <button
                                                className="select-all"
                                                type="button"
                                                onClick={() => {
                                                    if (
                                                        paymentFilter?.managementInfos &&
                                                        paymentFilter
                                                            .managementInfos
                                                            .length > 0
                                                    ) {
                                                        if (
                                                            hasOptionsAdded(
                                                                management.id
                                                            )
                                                        ) {
                                                            handlePaymentFilter(
                                                                {
                                                                    ...paymentFilter,
                                                                    managementInfos:
                                                                        [
                                                                            ...paymentFilter.managementInfos.filter(
                                                                                (
                                                                                    m
                                                                                ) =>
                                                                                    m.id !==
                                                                                    management.id
                                                                            ),
                                                                            {
                                                                                id: managementInfos.find(
                                                                                    (
                                                                                        m
                                                                                    ) =>
                                                                                        m.id ===
                                                                                        management.id
                                                                                )!
                                                                                    .id,
                                                                                options:
                                                                                    managementInfos
                                                                                        .find(
                                                                                            (
                                                                                                m
                                                                                            ) =>
                                                                                                m.id ===
                                                                                                management.id
                                                                                        )!
                                                                                        .options.map(
                                                                                            (
                                                                                                opt
                                                                                            ) =>
                                                                                                opt.id
                                                                                        ),
                                                                            },
                                                                        ],
                                                                }
                                                            )
                                                        }
                                                    }
                                                    handlePaymentFilter({
                                                        ...paymentFilter,
                                                        managementInfos: [
                                                            {
                                                                id: managementInfos.find(
                                                                    (m) =>
                                                                        m.id ===
                                                                        management.id
                                                                )!.id,
                                                                options:
                                                                    managementInfos
                                                                        .find(
                                                                            (
                                                                                m
                                                                            ) =>
                                                                                m.id ===
                                                                                management.id
                                                                        )!
                                                                        .options.map(
                                                                            (
                                                                                opt
                                                                            ) =>
                                                                                opt.id
                                                                        ),
                                                            },
                                                        ],
                                                    })
                                                }}
                                            >
                                                Selecionar todos
                                            </button>
                                            <button
                                                className="clear-all"
                                                type="button"
                                                onClick={() => {
                                                    if (
                                                        hasOptionsAdded(
                                                            management.id
                                                        )
                                                    ) {
                                                        handlePaymentFilter({
                                                            ...paymentFilter,
                                                            managementInfos: [
                                                                ...paymentFilter!.managementInfos!.filter(
                                                                    (m) =>
                                                                        m.id !==
                                                                        management.id
                                                                ),
                                                            ],
                                                        })
                                                    }
                                                }}
                                            >
                                                Limpar
                                            </button>
                                        </div>
                                    </div>
                                    {management.options.map((option) => {
                                        return (
                                            <Selector key={v4()}>
                                                <label
                                                    htmlFor={
                                                        option.id +
                                                        management.id
                                                    }
                                                    className="select-label"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        id={
                                                            option.id +
                                                            management.id
                                                        }
                                                        className="center"
                                                        checked={existThisOption(
                                                            management.id,
                                                            option.id
                                                        )}
                                                        onChange={(e) => {
                                                            if (
                                                                e.target.checked
                                                            ) {
                                                                handlePaymentFilter(
                                                                    addOptionToManagementInfo(
                                                                        management.id,
                                                                        option.id
                                                                    )
                                                                )
                                                            } else if (
                                                                paymentFilter?.productTypes
                                                            ) {
                                                                handlePaymentFilter(
                                                                    removeOptionFromManagementInfo(
                                                                        management.id,
                                                                        option.id
                                                                    )
                                                                )
                                                            }
                                                        }}
                                                    />
                                                    <p>{option.label}</p>
                                                </label>
                                            </Selector>
                                        )
                                    })}
                                </div>
                            </label>
                        </PeriodDropDown>
                    )
                })}
            </div>
            <div className="third-row">
                <PeriodDropDown>
                    <label
                        htmlFor="period"
                        className={`selector-label ${
                            paymentFilter?.productTypes &&
                            paymentFilter?.productTypes.length > 0
                                ? 'bordered'
                                : ''
                        }`}
                    >
                        <input
                            type="checkbox"
                            name="period"
                            checked={selectedPeriod}
                            id="period"
                            className="selector-input"
                            onChange={(e) =>
                                setSelectedPeriod(e.target.checked)
                            }
                        />
                        {paymentFilter?.productTypes &&
                        paymentFilter?.productTypes.length > 0 ? (
                            <h4>
                                {paymentFilter?.productTypes
                                    .map((product) => productLabel(product))
                                    .join(', ')}
                            </h4>
                        ) : (
                            <p>Todos os produtos</p>
                        )}
                        {paymentFilter?.productTypes &&
                        paymentFilter?.productTypes.length > 0 ? (
                            <AiFillCloseCircle
                                style={{ height: '1.6rem', width: '1.6rem' }}
                                onClick={() => {
                                    handlePaymentFilter({
                                        ...paymentFilter,
                                        productTypes: [],
                                    })
                                }}
                            />
                        ) : (
                            <></>
                        )}
                        <div className="selectors">
                            <div className="title-dropdown">
                                <h1>Produtos</h1>
                                <div className="btns">
                                    <button
                                        className="select-all"
                                        type="button"
                                        onClick={() => {
                                            handlePaymentFilter({
                                                ...paymentFilter,
                                                productTypes: products,
                                            })
                                        }}
                                    >
                                        Selecionar todos
                                    </button>
                                    <button
                                        className="clear-all"
                                        type="button"
                                        onClick={() => {
                                            handlePaymentFilter({
                                                ...paymentFilter,
                                                productTypes: [],
                                            })
                                        }}
                                    >
                                        Limpar
                                    </button>
                                </div>
                            </div>
                            {products &&
                                products.map((product) => {
                                    return (
                                        <Selector key={v4()}>
                                            <label
                                                htmlFor={product}
                                                className="select-label"
                                            >
                                                <input
                                                    type="checkbox"
                                                    id={product}
                                                    className="center"
                                                    checked={
                                                        !!paymentFilter?.productTypes?.find(
                                                            (o) => o === product
                                                        )
                                                    }
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            handlePaymentFilter(
                                                                {
                                                                    ...paymentFilter,
                                                                    productTypes:
                                                                        paymentFilter?.productTypes
                                                                            ? [
                                                                                  ...paymentFilter?.productTypes,
                                                                                  product,
                                                                              ]
                                                                            : [
                                                                                  product,
                                                                              ],
                                                                }
                                                            )
                                                        } else if (
                                                            paymentFilter?.productTypes
                                                        ) {
                                                            const temp: ProductTypes[] =
                                                                paymentFilter?.productTypes

                                                            const index =
                                                                temp.findIndex(
                                                                    (t) =>
                                                                        t ===
                                                                        product
                                                                )
                                                            temp.splice(
                                                                index,
                                                                1
                                                            )
                                                            handlePaymentFilter(
                                                                {
                                                                    ...paymentFilter,
                                                                    productTypes:
                                                                        temp,
                                                                }
                                                            )
                                                        }
                                                    }}
                                                />
                                                <p>{productLabel(product)}</p>
                                            </label>
                                        </Selector>
                                    )
                                })}
                        </div>
                    </label>
                </PeriodDropDown>
                <SelectInput
                    id="expenses"
                    name="expenses"
                    className="dropdown"
                    placeholder="Usável em despesas..."
                    options={[
                        { label: 'Usável em despesas', value: undefined },
                        { label: 'Sim', value: true },
                        { label: 'Não', value: false },
                    ]}
                    value={expenseOptionSelected()}
                    onChange={(e) => {
                        handlePaymentFilter({
                            ...paymentFilter,
                            canBeUsedOnExpenses: e!.value,
                        })
                    }}
                />
                <div className="align-right">
                    <div className="select-type">
                        <h1 className="f16-500-gray">
                            Exibir somente formas de pagamento ativas
                        </h1>
                        <ControlButton
                            type="SWITCH"
                            isPressed={paymentFilter.isActive ?? true}
                            id="status"
                            callback={() =>
                                handlePaymentFilter({
                                    ...paymentFilter,
                                    isActive: !paymentFilter.isActive,
                                })
                            }
                        />
                    </div>
                </div>
            </div>

            {selectedPeriod && (
                <div
                    className="disabled"
                    onClick={() => {
                        setSelectedPeriod(false)
                    }}
                />
            )}
            {selectedDrop && (
                <div
                    className="disabled"
                    onClick={() => {
                        setSelectedDrop(undefined)
                    }}
                />
            )}
            {openSelecteCustomData && (
                <DatePicker
                    hasRange
                    closeDatePicker={() => setOpenSelectCustomData(false)}
                />
            )}
            {openCreatorModal && (
                <ChoseOnneMemberModal
                    isOpen={openCreatorModal}
                    onRequestClose={() => setOpenCreatorModal(false)}
                    users={members.map((user) => {
                        return {
                            id: user.id,
                            label: `${user.firstName} ${user.lastName}`,
                            url: user.photo?.url,
                        }
                    })}
                    selected={accessibleByUser}
                    callback={(id) => {
                        if (id === accessibleByUser) {
                            setAccessibleByUser(undefined)
                            setOpenCreatorModal(false)
                            handlePaymentFilter({
                                ...paymentFilter,
                                accessibleByUserId: undefined,
                            })
                            return
                        }
                        handlePaymentFilter({
                            ...paymentFilter,
                            accessibleByUserId: id,
                        })
                        setAccessibleByUser(id)
                        setOpenCreatorModal(false)
                    }}
                />
            )}
        </FilterContainer>
    )
}
